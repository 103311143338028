@use "colors";

.link {
  text-decoration: none;
  color: colors.$color-text-primary;

  &\--underlined {
    text-decoration: underline;
  }

  &:hover {
    color: colors.$color-text-primary-hover;
  }

  &:focus-visible {
    color: colors.$color-text-primary-active;
  }

  &.inverse {
    color: colors.$color-text-inverse;

    &:focus-visible {
      outline: 0.2rem solid colors.$color-outline-focus !important;
    }
  }

  &:hover,
  &.inverse:hover {
    text-decoration: underline;
  }
}
