@use "functions";

input[type="radio"].form-check-input ~ .form-text {
  margin: 0;
}

.form-check.form-check-group {
  margin: 0;
  padding: 0;

  .form-check-label {
    display: block;
    padding: functions.toRem(16) functions.toRem(8);

    &::after,
    &::before {
      top: functions.toRem(15);
    }
  }
}
