// Utility
.remove-form-group-margin {
  .form-group {
    margin: 0;
  }
}

.search-input {
  flex: 2 auto;

  @extend .remove-form-group-margin;
}

input[type="date"] {
  display: inline-block;
}
