@use "functions";
@use "mixins";

.alert {
  padding: functions.toRem(24) functions.toRem(24) functions.toRem(24)
    functions.toRem(64);
  background-position: functions.toRem(23) functions.toRem(23);
  background-size: functions.toRem(24);
  margin-bottom: 0;

  @include mixins.media-breakpoint-up(lg) {
    background-size: functions.toRem(32);
  }

  .btn-close {
    top: functions.toRem(16);
    right: functions.toRem(16);
    transform: none;
    padding: 0;

    @include mixins.media-breakpoint-up(lg) {
      top: functions.toRem(24);
      right: functions.toRem(24);
    }

    svg.icon {
      width: functions.toRem(24);
      height: functions.toRem(24);

      @include mixins.media-breakpoint-up(lg) {
        width: functions.toRem(32);
        height: functions.toRem(32);
      }
    }
  }
}
