@use "functions" as f;
@use "colors" as c;

code-input {
  gap: f.toRem(8);
  justify-content: center;
  align-items: center;

  > span {
    --item-spacing: 0;
    flex: 0 1 f.toRem(40) !important;

    input {
      --item-border: #{f.toRem(2)} solid #{c.$color-border-subtle};
      --item-border-bottom: #{f.toRem(2)} solid #{c.$color-border-subtle};
      --item-border-focused: #{f.toRem(2)} solid #{c.$color-border-primary};
      --item-border-has-value: #{f.toRem(2)} solid #{c.$color-border-subtle};
      --item-border-bottom-focused: #{f.toRem(2)} solid
        #{c.$color-border-primary};
      --item-border-bottom-has-value: #{f.toRem(2)} solid
        #{c.$color-border-subtle};
      --item-height: #{f.toRem(56)};

      &.focus--mouse:not(.btn):focus,
      &:focus:not(.focus--mouse) {
        border: var(--item-border-focused) !important;
        border-bottom: var(--item-border-bottom-focused) !important;
      }
    }
  }
}
