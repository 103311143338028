.video-js .vjs-control.vjs-close-button {
  cursor: pointer;
  height: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
  z-index: 2;

  & .vjs-icon-placeholder {
    @extend .vjs-icon-cancel;
  }
}
