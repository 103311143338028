@use "functions";
@use "overrides/colors";
@use "mixins";
@use "sass:map";

@use "variables";

.caption {
  font-size: functions.toRem(14);
  line-height: functions.toRem(24);
  font-weight: 600;
}

.body {
  color: colors.$color-text-base;
  font-size: functions.toRem(16);
  line-height: functions.toRem(24);

  @include mixins.media-breakpoint-up(lg) {
    font-size: functions.toRem(18);
    line-height: functions.toRem(28);
  }
}

a.body {
  color: colors.$color-text-primary;
}

.small {
  font-size: functions.toRem(14);
  line-height: functions.toRem(24);
  font-weight: 400;
}

.heading-5 {
  font-size: functions.toRem(20);
  line-height: functions.toRem(24);
  font-weight: 600;
}

.small-description {
  font-size: functions.toRem(12);
  line-height: functions.toRem(12);
  font-weight: 400;
}

.link {
  font-weight: 600;
  font-size: functions.toRem(18);
  line-height: functions.toRem(28);
}

.label-lg {
  font-size: functions.toRem(18);
  line-height: functions.toRem(24);
  font-weight: 600;
}
