@use "functions";
@use "overrides/colors";

.dropdown-list-item-btn {
  font-size: functions.toRem(16);
  line-height: functions.toRem(32);
  padding: functions.toRem(4) functions.toRem(24);
  background-color: transparent;
  border: none;
  color: colors.$color-text-primary;
}
