@use "functions";
@use "mixins";

.form-group {
  margin-bottom: 0;

  label.active {
    padding: 0;
  }
}

.search-input {
  .form-group label {
    top: 0;

    @include mixins.media-breakpoint-up(lg) {
      top: functions.toRem(8);
    }
  }

  input {
    padding-left: 0;
  }

  .input-group .input-group-text ~ label {
    padding: 0;
    left: 0;

    &.active {
      left: functions.toRem(0);
    }
  }

  .input-group-append .input-group-text {
    height: 100%;
  }
}
