/* You can add global styles to this file, and also import other style files */

@use "./functions";
@use "./overrides/colors";
@use "mixins";
@use "components/dropdown";

@import "overrides/colors";
@import "./variables";

$primary-h: 160;
$primary-s: 100;
$primary-b: 48;
$primary-a7: $color-background-primary-dark;
$primary-a9: $color-background-primary;
$grid-breakpoints: $grid-breakpoints;

@import "bootstrap-italia/src/scss/bootstrap-italia";
@import "./overrides";
@import "./typography";

p {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

svg.custom-icon {
  width: 1em;
  height: 1em;
}

.toggles label input[type="checkbox"] + .lever {
  float: unset;
  display: inline-block;
  margin-left: functions.toRem(12);
}

.toggles > label {
  display: block !important;
  height: unset;
  gap: functions.toRem(12);
  line-height: functions.toRem(28) !important;
  margin: 0 !important;
}

.cup-reservation-card {
  width: 100%;
  height: 100%;
  transition: border-color 200ms ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: functions.toRem(24);
  border: 0.1rem solid colors.$color-border-subtle;
  border-radius: functions.toRem(4);
  padding: functions.toRem(24) functions.toRem(64) functions.toRem(72)
    functions.toRem(24);
  background-color: white;
  text-align: left;
  cursor: pointer;

  &--checked {
    border-color: colors.$color-border-primary;
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
}

svg.icon.icon-lg {
  width: functions.toRem(24);
  height: functions.toRem(24);

  @include mixins.media-breakpoint-up(lg) {
    width: functions.toRem(40);
    height: functions.toRem(40);
  }
}

cup-header {
  .header__top it-language-switcher it-icon .icon {
    color: #fff;
  }

  .header__bottom .icon {
    width: functions.toRem(40);
    height: functions.toRem(40);

    @include mixins.media-breakpoint-up(lg) {
      width: functions.toRem(72);
      height: functions.toRem(72);
    }
  }
}

.d-contents {
  display: contents;
}

header it-dropdown button.btn.btn-dropdown.dropdown-toggle {
  display: flex;
  align-items: center;
  gap: functions.toRem(8);

  span[button] {
    color: #fff;

    + it-icon .icon-primary {
      fill: #fff !important;
    }
  }

  &::after {
    display: none;
  }
}

it-input {
  .form-group {
    height: 100%;

    .input-group {
      height: 100%;
    }
  }
}

.error.notification.dismissable {
  word-break: break-word;
}

.break-word {
  word-break: break-word;
}
