@use "overrides/colors";

.breadcrumb-container {
  .breadcrumb {
    --bs-breadcrumb-padding-x: 0;
    --bs-breadcrumb-padding-y: 0;
    --bs-breadcrumb-margin-bottom: 0;
    padding: 0;

    .breadcrumb-item {
      color: colors.$color-text-secondary;

      span.separator {
        color: colors.$color-background-secondary;
      }

      a {
        color: colors.$color-text-secondary-active;
      }
    }
  }
}
