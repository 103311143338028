@use "functions";

.text-body {
  color: inherit !important;
  font-weight: 400;
  font-size: functions.toRem(16);
  line-height: functions.toRem(24);
}

.card:has(.text-with-icon) .icon {
  min-width: auto;
}

.text-with-icon {
  display: flex;
  gap: functions.toRem(8);
  align-items: center;
}

svg.icon {
  width: functions.toRem(24);
  height: functions.toRem(24);
}
