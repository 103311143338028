.video-js .vjs-mute-control {
  cursor: pointer;
  @include flex(none);

  & .vjs-icon-placeholder {
    @extend .vjs-icon-volume-high;
  }
}

.video-js .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder {
  @extend .vjs-icon-volume-mute;
}
.video-js .vjs-mute-control.vjs-vol-1 .vjs-icon-placeholder {
  @extend .vjs-icon-volume-low;
}
.video-js .vjs-mute-control.vjs-vol-2 .vjs-icon-placeholder {
  @extend .vjs-icon-volume-mid;
}

.video-js .vjs-volume-control {
  cursor: pointer;
  margin-right: 1em;
  @include display-flex;
}
.video-js .vjs-volume-control.vjs-volume-horizontal {
  width: 5em;
}

.video-js .vjs-volume-panel .vjs-volume-control {
  visibility: visible;
  opacity: 0;
  width: 1px;
  height: 1px;
  margin-left: -1px;
}

.video-js .vjs-volume-panel {
  @include transition(width 1s);

  &.vjs-hover .vjs-volume-control,
  &:active .vjs-volume-control,
  &:focus  .vjs-volume-control,
  & .vjs-volume-control:active,
  &.vjs-hover .vjs-mute-control  ~ .vjs-volume-control,
  & .vjs-volume-control.vjs-slider-active {
    visibility: visible;
    opacity: 1;
    position: relative;
    $transition-property: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s;
    @include transition($transition-property);

    &.vjs-volume-horizontal {
      width: 5em;
      height: 3em;
      margin-right: 0;
    }

    &.vjs-volume-vertical {
      left: -3.5em;
      @include transition(left 0s);
    }
  }

  &.vjs-volume-panel-horizontal {
    &.vjs-hover,
    &:active,
    &.vjs-slider-active {
      width: 10em;

      @include transition(width 0.1s);
    }
    &.vjs-mute-toggle-only {
      width: 4em;
    }
  }
}

.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
  height: 8em;
  width: 3em;
  left: -3000em;

  $transition-property: visibility 1s, opacity 1s, height 1s 1s, width 1s 1s, left 1s 1s, top 1s 1s;
  @include transition($transition-property)
}

.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal {
  $transition-property: visibility 1s, opacity 1s, height 1s 1s, width 1s, left 1s 1s, top 1s 1s;
  @include transition($transition-property)
}

.video-js .vjs-volume-panel {
  @include display-flex;
}

.video-js .vjs-volume-bar {
  margin: 1.35em 0.45em;
}

.vjs-volume-bar.vjs-slider-horizontal {
  width: 5em;
  height: 0.3em;
}

.vjs-volume-bar.vjs-slider-vertical {
  width: 0.3em;
  height: 5em;
  margin: 1.35em auto;
}

.video-js .vjs-volume-level {
  position: absolute;
  bottom: 0;
  left: 0;

  background-color: $primary-foreground-color;

  @extend .vjs-icon-circle;

  // Volume handle
  &:before {
    position: absolute;
    font-size: 0.9em; // Doing this to match the handle on play progress.
    z-index: 1;
  }
}

.vjs-slider-vertical .vjs-volume-level {
  width: 0.3em;

  // Volume handle
  &:before {
    top: -0.5em;
    left: -0.3em;
    z-index: 1;
  }
}
// Remove content from volume-level when using SVGs.
.vjs-svg-icons-enabled .vjs-volume-level {
  &:before {
    content: none;
  }
}

.vjs-volume-level .vjs-svg-icon {
  position: absolute;
  width: 0.9em;
  height: 0.9em;
  pointer-events: none;
  z-index: 1;
}

.vjs-slider-horizontal .vjs-volume-level {
  height: 0.3em;

  // Volume handle
  &:before {
    line-height: .35em;
    right: -0.5em;
  }
}

// here
// Update placement of circle icon when using SVG icons
.vjs-slider-horizontal .vjs-volume-level .vjs-svg-icon {
  right: -0.3em;
  transform: translateY(-50%);
}
.vjs-slider-vertical .vjs-volume-level .vjs-svg-icon {
  top: -0.55em;
  transform: translateX(-50%);
}

.video-js .vjs-volume-panel.vjs-volume-panel-vertical {
  width: 4em;
}

// Assumes volume starts at 1.0.
.vjs-volume-bar.vjs-slider-vertical .vjs-volume-level {
  height: 100%;
}

.vjs-volume-bar.vjs-slider-horizontal .vjs-volume-level {
  width: 100%;
}

.video-js .vjs-volume-vertical {
  width: 3em;
  height: 8em;
  bottom: 8em;

  @include background-color-with-alpha($primary-background-color, $primary-background-transparency);
}

.video-js .vjs-volume-horizontal .vjs-menu {
  left: -2em;
}

// .vjs-volume-tooltip
//
// These elements are displayed above the volume bar.
//
// By default, they are hidden and only shown when hovering over the volume
// control.
.video-js .vjs-volume-tooltip {
  @include background-color-with-alpha(#fff, 0.8);
  @include border-radius(0.3em);
  color: #000;
  float: right;
  font-family: $text-font-family;
  font-size: 1em;
  padding: 6px 8px 8px 8px;
  pointer-events: none;
  position: absolute;
  top: -3.4em;
  visibility: hidden;
  z-index: 1;
}

.video-js .vjs-volume-control:hover .vjs-volume-tooltip,
.video-js .vjs-volume-control:hover .vjs-progress-holder:focus .vjs-volume-tooltip {
  display: block;
  font-size: 1em;
  visibility: visible;
}

.video-js .vjs-volume-vertical:hover .vjs-volume-tooltip,
.video-js .vjs-volume-vertical:hover .vjs-progress-holder:focus .vjs-volume-tooltip {
  left: 1em;
  top: -12px;
}

.video-js .vjs-volume-control.disabled:hover .vjs-volume-tooltip {
  font-size: 1em;
}

// .vjs-mouse-display / MouseVolumeLevelDisplay
//
// This element tracks the mouse position along the volume control and
// includes a tooltip, which displays the volume level.
.video-js .vjs-volume-control .vjs-mouse-display {
  display: none;
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #000;
  z-index: 1;
}

.video-js .vjs-volume-horizontal .vjs-mouse-display {
  width: 1px;
  height: 100%;
}

.video-js .vjs-volume-control:hover .vjs-mouse-display {
  display: block;
}

.video-js.vjs-user-inactive .vjs-volume-control .vjs-mouse-display {
  visibility: hidden;
  opacity: 0;
  $trans: visibility 1.0s, opacity 1.0s;
  @include transition($trans);
}

.vjs-mouse-display .vjs-volume-tooltip {
  color: #fff;
  @include background-color-with-alpha(#000, 0.8);
}
