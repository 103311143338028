@import "icons";
@import "variables";
@import "private-variables";
@import "utilities";

@import "videojs-font/scss/icons";

@import "components/layout";
@import "components/big-play";
@import "components/button";
@import "components/close-button";
@import "components/modal-dialog";

@import "components/menu/menu";
@import "components/menu/menu-popup";
@import "components/menu/menu-inline";

@import "components/control-bar";
@import "components/control";
@import "components/control-spacer";

@import "components/progress";
@import "components/slider";

@import "components/volume";

@import "components/poster";
@import "components/live";
@import "components/time";
@import "components/play-pause";
@import "components/text-track";
@import "components/picture-in-picture";
@import "components/fullscreen";
@import "components/playback-rate";
@import "components/error";
@import "components/loading";
@import "components/captions";
@import "components/chapters";
@import "components/descriptions";
@import "components/subtitles";
@import "components/subs-caps";
@import "components/audio";
@import "components/adaptive";
@import "components/captions-settings";
@import "components/title-bar";
@import "components/skip-buttons";
@import "components/transient-button";

@import "print";

.vjs-resize-manager {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  z-index: -1000;
}

// The rule is needed for :focus-visible polyfill
.js-focus-visible .video-js *:focus:not(.focus-visible) {
  outline: none;
}

.video-js *:focus:not(:focus-visible) {
  outline: none;
}
