//mobile
.skiplinks {
  background-color: $skiplinks-bg;
  text-align: center;

  a {
    padding: $skiplink-padding-y $skiplink-padding-x;
    display: block;
    font-weight: 600;
    color: $skiplinks-color;
    text-decoration: underline;
  }

  ul {
    list-style: none;
    padding: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0;
  }
}
