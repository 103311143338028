$color-theme-base: #0066cc;
$color-gray-96: #f5f5f5;

// Text
$color-text-primary: #007a52;
$color-text-primary-hover: #006242;
$color-text-primary-active: #003121;
$color-text-secondary: #17324d;
$color-text-secondary-active: #17324d;
$color-text-inverse: #ffffff;
$color-text-disabled: #5c6f82;
$color-text-warning: #995c00;
$color-outline-focus: #1a1a1a;
$color-text-base: #1a1a1a;

.text-secondary {
  color: $color-text-secondary !important;
}

.text-warning {
  color: $color-text-warning !important;
}

// Background
$color-background-primary: #007a52;
$color-background-primary-dark: #00402b;
$color-background-primary-lighter: #e1f2ec;
$color-background-secondary: #5c6f82;
$color-background-muted: #f5f5f5;
$color-background-disabled: #d9dadb;
$footer-bottom-background: #202a2e;

// Border
$color-border-primary: $color-background-primary;
$color-border-secondary: $color-background-secondary;
$color-border-warning: $color-text-warning;
$color-border-subtle: #c5c7c9;
$color-border-disabled: #d9dadb;
