@use "functions";
@use "colors";
@use "mixins";

.checkbox-wrapper {
  display: flex;
  gap: functions.toRem(8);
  align-items: flex-start;
  min-height: functions.toRem(28);

  &:has(.form-checkbox.ng-invalid.ng-touched) label {
    color: colors.$color-text-warning;
  }
}

.checkbox-button[aria-checked="true"] {
  background-color: colors.$color-background-primary;
  border-color: colors.$color-border-primary !important;
}

.checkbox-button {
  flex-shrink: 0;
  transition:
    background-color 200ms ease-in-out,
    border-color 200ms ease-in-out;
  background-color: white;
  border: functions.toRem(2) solid colors.$color-border-secondary;
  border-radius: functions.toRem(4);
  width: functions.toRem(20);
  height: functions.toRem(20);
  font-size: functions.toRem(24);
  margin-top: functions.toRem(2);
  padding: 0;

  &.custom-focus,
  &:focus-visible {
    box-shadow:
      0 0 0 #{functions.toRem(1)} #fff,
      0 0 #{functions.toRem(2)} #{functions.toRem(3)}
        #{lighten(colors.$color-background-primary, 4)} !important;
  }

  &:disabled {
    border-color: colors.$color-border-disabled;
  }
}

it-checkbox:has(.lever) {
  .toggles label {
    display: flex;
    font-size: functions.toRem(16);
    line-height: functions.toRem(24);
    font-weight: 600;
    align-items: center;
    margin: 0 0 functions.toRem(48);
    flex-wrap: wrap;
    gap: functions.toRem(16);

    @include mixins.media-breakpoint-up(sm) {
      margin: 0;
    }

    @include mixins.media-breakpoint-up(lg) {
      flex-direction: row;
    }

    .ng-star-inserted {
      display: none;
    }

    .lever {
      margin: 0;
    }
  }

  .form-check {
    margin: 0;
  }
}
