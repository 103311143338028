@use "overrides/colors";
@use "overrides/inputs";
@use "overrides/breadcrumb";
@use "overrides/carousel";
@use "overrides/button";
@use "overrides/links";
@use "overrides/text";
@use "overrides/stepper";
@use "overrides/modal";
@use "overrides/radio";
@use "overrides/forms";
@use "overrides/checkbox";
@use "overrides/alert";
@use "overrides/code-input";
@use "overrides/progress-spinner";
@use "functions";

hr {
  border-top: 0.1rem solid colors.$color-border-subtle;
  opacity: 1;
  margin-top: functions.toRem(24);
  margin-bottom: functions.toRem(48);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
