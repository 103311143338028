//mobile
.it-list-wrapper {
  .it-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .list-item {
      transition: all 0.3s;
      margin-top: -1px;
      display: flex;
      align-items: center;
      text-decoration: none;
      border-bottom: 1px solid $list-border-color;
      overflow-wrap: anywhere;

      .avatar,
      .it-rounded-icon,
      .it-thumb {
        flex-shrink: 0;
        margin-right: $v-gap * 2;
      }

      .it-rounded-icon {
        width: $v-gap * 5;

        svg {
          fill: $primary-a5;
        }
      }

      .form-check {
        margin-right: $v-gap;
        width: $v-gap;
        height: $v-gap * 4;
        text-align: left;

        & + .it-right-zone {
          margin-left: $v-gap * 2;
        }

        label {
          padding-left: 0;
          margin-bottom: 0;
        }
      }

      .it-thumb {
        width: $v-gap * 5;
        height: $v-gap * 5;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .it-right-zone {
        padding: $list-text-padding;
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
          fill: $primary;
          width: $v-gap * 3;
          height: $v-gap * 3;
        }

        .it-multiple {
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;

          .metadata {
            margin-right: 0;
            width: 100%;
            text-align: right;
          }

          svg {
            margin-left: $v-gap * 2;
            margin-right: 0;
          }
        }

        .toggles {
          height: $v-gap * 4;
        }

        .metadata {
          color: $list-metadata-color;
          font-size: $list-metadata-size;
          letter-spacing: $list-metadata-space;
        }

        .text {
          font-size: $list-font-size;
          font-weight: $font-weight-semibold;
          @include media-breakpoint-up(lg) {
            font-size: 1.125rem;
          }
        }

        .text + p {
          font-size: $list-sub-size;
          color: $list-sub-color;
          font-weight: $font-weight-normal;
        }
      }
    }

    li:last-child {
      .list-item {
        span.text {
          border-bottom: 1px solid transparent;
        }
      }
    }

    a .text {
      text-decoration: underline;
    }
  }
}

// Desktop
@include media-breakpoint-up(xl) {
  .it-list-wrapper {
    .it-list {
      .list-item {
        .avatar,
        .it-rounded-icon,
        .it-thumb {
          margin-left: $v-gap;
          margin-right: 0;

          & + .it-right-zone {
            margin-left: $v-gap * 2;
          }
        }

        .form-check {
          margin-right: $v-gap * 2;
          text-align: center;
        }

        // hover
        .it-right-zone {
          margin-left: $v-gap;
          margin-right: $v-gap;

          span.it-multiple {
            margin-right: $v-gap;

            a {
              svg {
                transition: fill 0.3s;
              }

              &:hover {
                svg {
                  fill: $primary-a10;
                }
              }
            }
          }

          .toggles {
            margin-right: 0;
          }

          span.metadata {
            margin-right: $v-gap;
          }
        }
      }

      a.list-item:hover {
        box-shadow: $card-shadow;
        color: $color-text-primary-hover;
        text-decoration: none;
        background: $white;
        position: relative;
        z-index: 1;
        transition: none;
        border-bottom: 1px solid transparent;
      }
    }
  }
}
