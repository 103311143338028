@use "colors";
@use "functions";
@use "variables";
@use "mixins";

.btn {
  --bs-btn-color: #{colors.$color-text-primary};
  --bs-btn-hover-color: #{colors.$color-text-primary-hover};
  --bs-btn-active-color: #{colors.$color-text-primary-active};

  padding: functions.toRem(12) functions.toRem(24);

  @include mixins.media-breakpoint-up(lg) {
    padding: functions.toRem(16) functions.toRem(24);
  }

  &.link {
    padding: 0;
    text-transform: uppercase;
    font-size: functions.toRem(14);
    line-height: functions.toRem(16);
    border-radius: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  &-primary {
    &.disabled,
    &.disabled.btn-progress,
    &:disabled,
    &:disabled.btn-progress {
      background-color: colors.$color-background-disabled;
      color: colors.$color-text-disabled;
    }
  }
}

button.dropdown-list-item-btn:focus-visible,
a:not(.btn):focus-visible {
  text-decoration: underline !important;
}

:focus:not(.focus--mouse) {
  box-shadow: none;
}

a,
btn {
  &:focus:not(.focus--mouse) {
    border-color: transparent !important;
  }
}

.form-check [type="radio"]:focus + label,
input:focus:not(.focus--mouse),
input.custom-focus.focus--mouse:not(.btn),
input:focus-visible,
a:focus-visible,
button:focus-visible {
  outline: none !important;
  border-color: transparent !important;
  box-shadow:
    0 0 0 #{functions.toRem(1)} #fff,
    0 0 #{functions.toRem(4)} #{functions.toRem(3)}
      #{lighten(colors.$color-background-primary, 4)} !important;
}

.focus--mouse {
  outline: none !important;
}

a.link.inverse:focus-visible {
  outline: none !important;
}

a.btn.btn-outline-primary:focus-visible {
  box-shadow:
    0 0 #{functions.toRem(4)} 0 colors.$color-border-primary,
    inset 0 0 0 #{functions.toRem(2)} colors.$color-border-primary !important;
}

button.chip[role="radio"]:focus-visible > span {
  > span {
    text-decoration: underline;
  }
}

ul.splide__list.splide__list {
  padding: #{functions.toRem(8)} !important;
}
