@mixin button-variant(
  $background,
  $border,
  $hover-background: shade-color($background, 19%),
  $hover-border: shade-color($border, 25%),
  $active-background: shade-color($background, 25%),
  $active-border: shade-color($border, 31%)
) {
  color: color-contrast($background);
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  &:hover,
  &:focus-visible {
    background-color: $hover-background;
    color: color-contrast($background);
    border-color: $hover-border;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: color-contrast($background);
    background-color: $background;
    border-color: $border;
    pointer-events: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &.dropdown-toggle.show,
  .show > &.dropdown-toggle {
    background-color: $hover-background;
    color: color-contrast($background);
    border-color: $hover-border;
  }
}

@mixin button-outline-variant($color, $color-hover: color-hover($color), $active-background: $color, $active-border: $color) {
  color: $color;
  background-color: transparent;
  background-image: none;
  box-shadow: inset 0 0 0 2px $color;

  &:hover,
  &:focus-visible {
    color: $color-hover;
    box-shadow: inset 0 0 0 2px color-hover($color);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &.dropdown-toggle.show,
  .show > &.dropdown-toggle {
    color: $color-hover;
    background-color: transparent;
    background-image: none;
    box-shadow: inset 0 0 0 2px $color-hover;
  }
}
