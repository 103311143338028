.btn {
  //btn-css-vars
  --bs-btn-color: $primary;
  --bs-btn-hover-color: $color-background-primary-hover;
  --bs-btn-active-color: #003366; // TODO change with tokens when available
  //btn-css-vars-end
  padding: 12px 24px;
  font-size: $btn-font-size;
  white-space: initial;
  text-decoration: none;
  box-shadow: none;
}

.btn-me {
  margin-right: $v-gap * 1.5 !important;
}

.btn-xs,
.btn-sm,
.btn-lg {
  border-radius: $btn-border-radius;
}

.btn-xs {
  padding: 12px 16px;
  font-size: $btn-font-size-xs;
  line-height: $input-btn-line-height-xs;
}

.btn-sm {
  padding: 12px 24px;
  font-size: $btn-font-size-sm;
  line-height: $input-btn-line-height-sm;
}

.btn-lg {
  padding: 16px 24px;
  font-size: $btn-font-size-lg;
  line-height: $input-btn-line-height-lg;
}

.btn-progress {
  position: relative;
}

.btn-close {
  background-color: transparent;
  position: relative;
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.btn-icon {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .rounded-icon {
    width: 1.5em;
    height: 1.5em;

    .icon {
      margin-right: 0;
    }

    @include border-radius(12px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;

    @each $color, $value in $theme-colors {
      &.rounded-#{$color} {
        background-color: $value;
      }
    }

    & + * {
      margin-left: 0.5em;
    }
  }

  .icon {
    border: none;

    width: 1.2em;
    height: 1.2em;

    & + * {
      margin-left: 0.25em;
    }
  }
}

.btn-full {
  border: none;
  box-shadow: none;
  line-height: 1.555;
  @include border-radius(0);
  // allow height 100%
  align-self: stretch;
  // height: 100%;
  width: inherit;
  margin-top: -$header-slim-button-v-padding;
  margin-bottom: -$header-slim-button-v-padding;
  margin-right: -$header-slim-button-v-padding;
  padding-left: 8px;
  padding-right: 8px;
  &:hover {
    text-decoration: none !important;
  }

  // Tablet vertical
  @include media-breakpoint-up(sm) {
    padding: 16px;
  }

  // Desktop
  @include media-breakpoint-up(lg) {
    padding: 12px 24px !important;
    margin: 0;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.btn:disabled:hover,
.btn.disabled:hover {
  cursor: not-allowed;
}

.btn-primary {
  &:disabled,
  &.disabled {
    // color: $gray-label-disabled;
    // background-color: $gray-disabled;
    // border-color: $gray-border-disabled;
    //progress bar button
    &.btn-progress {
      background-color: hsl(210, 76%, 67%); // UI kit - missing token
      border-color: hsl(210, 76%, 67%); // UI kit - missing token
      color: $white;
      opacity: 1;
    }
  }
}

.btn-secondary {
  &:disabled,
  &.disabled {
    //progress bar button
    &.btn-progress {
      background-color: hsl(210, 12%, 52%); // UI kit - missing tokens
      border-color: hsl(210, 12%, 52%); // UI kit - missing tokens
      opacity: 1;
    }
  }
}

.btn-outline {
  &-primary {
    box-shadow: inset 0 0 0 2px $primary;
    &.disabled {
      box-shadow: inset 0 0 0 2px color-hover($primary);
    }
  }
  &-secondary {
    box-shadow: inset 0 0 0 2px $secondary;
    &.disabled,
    &:hover,
    &:active {
      box-shadow: inset 0 0 0 2px color-hover($secondary);
    }
  }
  &-success {
    box-shadow: inset 0 0 0 2px $success;
    &.disabled,
    &:hover,
    &:active {
      box-shadow: inset 0 0 0 2px color-hover($success);
    }
  }
  &-warning {
    box-shadow: inset 0 0 0 2px $color-border-warning;
    &.disabled,
    &:hover,
    &:active {
      box-shadow: inset 0 0 0 2px color-hover($color-border-warning);
    }
  }
  &-danger {
    box-shadow: inset 0 0 0 2px $danger;
    &.disabled,
    &:hover,
    &:active {
      box-shadow: inset 0 0 0 2px color-hover($danger);
    }
  }
}

.bg-dark {
  .btn-link {
    color: $white;
  }
  .btn-primary {
    @include button-variant($white, $primary, tint-color($white, 7.5%), shade-color($primary, 10%), tint-color($white, 10%), shade-color($primary, 12.5%));
    color: $primary;
    &.disabled,
    &:disabled {
      color: shade-color($primary, 10%);
    }
  }
  .btn-outline-primary {
    @include button-outline-variant($white, color-hover($white), transparent, color-hover($white));
    box-shadow: inset 0 0 0 2px $white;
    &:hover {
      box-shadow: inset 0 0 0 2px color-hover($white);
    }
  }
  .btn-secondary {
    @include button-variant($primary, $white, tint-color($primary, 7.5%), shade-color($white, 10%), tint-color($primary, 10%), shade-color($white, 12.5%));
    color: $white;
  }
  .btn-outline-secondary {
    @include button-outline-variant($white, color-hover($white), transparent, color-hover($white));
  }
}
