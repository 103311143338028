/* stylelint-disable */
@import '@splidejs/splide/dist/css/splide-core.min';
/* stylelint-enable */

//mobile
.it-carousel-wrapper {
  // splide pagination
  .splide__pagination {
    margin-top: $v-gap;
    margin-left: 0;
    padding-left: 0;
    button {
      width: $crs-dots-simension;
      height: $crs-dots-simension;
      background: $crs-dots-color;
      display: inline-block;
      transition: all 0.3s;
      border-radius: 50px;
      margin: 0 $v-gap;
      padding: 0;
      box-shadow: none;
      border: none;
      &.is-active {
        background: $primary;
      }
    }
  }
  .it-special-card {
    h5.card-title {
      margin-bottom: 0;
    }
  }
  margin: 0 auto;
  .it-header-block {
    h2 {
      font-size: $crs-heading-h-size;
      border-bottom: 1px solid $card-border-color;
      padding-bottom: $v-gap;
      margin-bottom: 0;
      letter-spacing: unset;
    }
  }
  .splide__track {
    padding-top: $crs-margin-bottom;
    padding-bottom: 0;
  }
  .card-wrapper {
    padding-bottom: $v-gap * 2;
  }
  // Landscape abstract
  &.it-carousel-landscape-abstract {
    .it-single-slide-wrapper {
      background-color: $white;
      > a {
        position: relative;
        display: block;
      }
      .it-text-slider-wrapper-outside {
        .card {
          border-right: none;
          margin-right: 0;
          &:after {
            display: none;
          }
          .card-body {
            .card-signature {
              float: left;
              margin-bottom: 0;
              width: 60%;
            }
            a.read-more {
              float: right;
              position: inherit;
              bottom: inherit;
              margin-bottom: 0;
              width: 35%;
              justify-content: flex-end;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
  &.it-carousel-landscape-abstract-three-cols-arrow-visible {
    .splide__arrows {
      position: absolute;
      top: 50%;
      left: -$v-gap * 3;
      right: -$v-gap * 3;
      display: flex;
      z-index: 1;
      justify-content: space-between;
      pointer-events: none;
      .splide__arrow {
        width: $v-gap * 5;
        height: $v-gap * 5;
        background: $primary;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: all;
        svg {
          fill: $white;
          width: $v-gap * 2;
          height: $v-gap * 2;
        }
        &.splide__arrow--prev {
          transform: rotate(180deg);
        }
        &[disabled] {
          background: $gray-disabled;
        }
      }
    }
  }
  // 3 col card
  &.it-carousel-landscape-abstract-3 {
    .it-header-block {
      padding: 0 $crs-heading-h-padding;
    }
    margin: 0 auto;
    // with img
    .card.card-img {
      margin-left: 0;
      margin-right: 0;
    }
    // with shadow
    .it-card-bg {
      .card.card-bg {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  &.it-calendar-wrapper {
    .splide__track {
      padding-top: 0;
      .card.card-bg {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .it-carousel-wrapper {
    // Landscape abstract
    &.it-carousel-landscape-abstract {
      max-width: #{map-get($container-max-widths, lg)};
      .splide__list {
        display: flex;
        .splide__slide {
          display: flex;
          .it-single-slide-wrapper {
            height: 100%;
          }
        }
      }
      .it-single-slide-wrapper {
        display: flex;
        flex-basis: 100%;
        width: 100%;
        > a {
          flex-basis: 50%;
          // img
          .img-responsive-wrapper {
            .img-responsive {
              padding-bottom: inherit;
              position: absolute;
              width: 100%;
              height: 100%;
            }
          }
        }
        .it-text-slider-wrapper-outside {
          flex-basis: 50%;
          .card {
            border-right: none;
            margin-right: 0;
            &:after {
              display: none;
            }
            .card-body {
              padding-left: $v-gap * 6;
              padding-bottom: $v-gap * 6;
              .card-signature {
                float: left;
                width: 60%;
              }
              a.read-more {
                float: right;
                position: inherit;
                bottom: inherit;
                margin-bottom: 0;
                width: 35%;
                justify-content: flex-end;
                line-height: 26px;
              }
            }
          }
        }
      }
      .splide__pagination {
        position: absolute;
        left: 50%;
        margin-left: $v-gap * 5;
        right: 0;
        bottom: $crs-landcape-bottom;
        text-align: left;
        justify-content: left;
      }
    }
    &.it-carousel-landscape-abstract-three-cols-arrow-visible {
      .lined_slide {
        &:after {
          content: '';
          display: block;
          width: 1px;
          position: absolute;
          right: -$v-gap * 1.5;
          top: 0;
          bottom: 0;
          background-color: $neutral-2; // Missing in the UI kit
        }
      }
    }
    // 3 col card
    &.it-carousel-landscape-abstract-3 {
      max-width: #{map-get($container-max-widths, lg)};
      &.it-full-carousel {
        max-width: unset;
      }
      .it-header-block {
        padding: 0 $crs-heading-h-padding * 0.5;
      }
    }
    &.it-carousel-landscape-abstract-three-cols-arrow-visible .splide__arrows {
      left: -$v-gap * 6;
      right: -$v-gap * 6;
    }
  }
}

//Desktop
@media (min-width: #{map-get($grid-breakpoints, xl)}) {
  .it-carousel-wrapper {
    // Landscape abstract
    &.it-carousel-landscape-abstract {
      max-width: #{map-get($container-max-widths, xl)};
    }
    // 3 col card
    &.it-carousel-landscape-abstract-3 {
      max-width: #{map-get($container-max-widths, xl)};
      &.it-full-carousel {
        max-width: unset;
      }
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .it-carousel-wrapper .splide__pagination button {
    transition: none !important;
  }
}
