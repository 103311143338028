@use "functions";
@use "colors";
@use "mixins";

.modal-footer > * {
  margin: unset;
}

.modal-footer {
  gap: functions.toRem(16);
}

.modal .modal-dialog {
  margin: 0;
}

.modal .modal-dialog.modal-dialog-centered {
  margin: 0 auto;
  min-height: 100%;
}

$size-sm: 24;
$size-lg: 32;

.modal .modal-dialog .modal-content {
  .btn-close {
    width: functions.toRem($size-sm);
    height: functions.toRem($size-sm);

    @include mixins.media-breakpoint-up(lg) {
      width: functions.toRem($size-lg);
      height: functions.toRem($size-lg);
    }
  }

  .modal-header {
    padding: functions.toRem($size-sm) functions.toRem($size-sm) 0;
    gap: functions.toRem(40);
    align-items: flex-start;

    @include mixins.media-breakpoint-up(lg) {
      align-items: center;
      padding: functions.toRem($size-lg) functions.toRem($size-lg) 0;
    }
  }

  .modal-body {
    padding: functions.toRem($size-sm);

    @include mixins.media-breakpoint-up(lg) {
      padding: functions.toRem($size-lg);
    }
  }

  .modal-footer {
    padding: 0 functions.toRem($size-sm) functions.toRem($size-sm);

    @include mixins.media-breakpoint-up(lg) {
      padding: 0 functions.toRem($size-lg) functions.toRem($size-lg);
    }

    @include mixins.media-breakpoint-down(lg) {
      flex-direction: column;

      button {
        width: 100%;
      }
    }
  }
}

@mixin modal-dialog {
  .modal-dialog {
    max-width: unset;
    margin: 0;
    width: 100%;

    &.modal-dialog-centered,
    .modal-content {
      min-height: 100vh;
    }
  }
}

.fullscreen-modal {
  @include modal-dialog;
}

.mobile-fullscreen-modal {
  @media (max-width: 744px) {
    @include modal-dialog;
  }
}
