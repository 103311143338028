@use "functions";
@use "typography";
@use "overrides/colors";

.cup-carousel {
  .it-carousel-wrapper.it-carousel-landscape-abstract {
    max-width: unset;
  }

  .splide__list {
    gap: functions.toRem(16) !important;
  }

  .splide__slide {
    width: auto !important;
    min-width: fit-content;
    margin-right: 0 !important;
  }

  .it-carousel-wrapper {
    .splide__track {
      padding-top: 0;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .splide__pagination__page {
      display: none;
    }

    margin: 0;
  }

  button.chip {
    border-radius: functions.toRem(100);
    height: auto;
    margin: 0;
    display: flex;
    padding: functions.toRem(4) functions.toRem(24);
    align-items: center;
    justify-content: center;

    .chip-label {
      font-size: functions.toRem(18);
      line-height: functions.toRem(24);
      color: colors.$color-text-primary;
      height: auto;
      transform: none;
    }
  }
}
