@use "sass:map";
@use "variables";

@mixin flex-align-centered {
  display: flex;
  align-items: center;
}

@mixin flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin media-breakpoint-up($breakpoint) {
  $min: map.get(variables.$grid-breakpoints, $breakpoint);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($breakpoint) {
  $max: map.get(variables.$grid-breakpoints, $breakpoint);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
