@use "functions";
@use "colors";
@use "typography";
@use "mixins";

.steppers {
  .steppers-nav {
    padding: 0;

    button .icon {
      display: block;
    }
  }

  .steppers-header {
    @include mixins.media-breakpoint-down(lg) {
      border-bottom: 0.1rem solid;
      border-bottom-color: colors.$color-border-subtle;
    }

    li.active:after {
      width: 100%;
    }

    .steppers-steps-list {
      color: colors.$color-text-secondary;
      font-weight: 600;
      list-style: none;
      display: flex;
      width: 100%;
      margin: 0;
      padding: 0;

      li {
        font-size: functions.toRem(18);

        &.active,
        &.confirmed {
          color: colors.$color-text-primary;
        }
      }
    }
  }

  .steppers-header,
  .custom-steppers-nav {
    box-shadow: none;

    @include mixins.media-breakpoint-down(lg) {
      margin: 0 functions.toRem(-12);
      padding-right: functions.toRem(12);
      padding-left: functions.toRem(12);
    }
  }

  .custom-steppers-nav {
    margin: 0;
    position: sticky;
    background-color: #fff;
    bottom: 0;
    height: auto;
    display: flex;
    border-top: 0.1rem solid colors.$color-border-subtle;
    padding: functions.toRem(16) 0;
    gap: functions.toRem(16);
    justify-content: space-between;
    flex-direction: column-reverse;

    @include mixins.media-breakpoint-up(lg) {
      flex-direction: row;
    }

    button {
      @include mixins.media-breakpoint-down(lg) {
        flex: 1;
        justify-content: center;
        padding: functions.toRem(12);
      }
    }
  }

  .steppers-content {
    padding: functions.toRem(41) 0 functions.toRem(48);

    @include mixins.media-breakpoint-up(lg) {
      padding: functions.toRem(48) 0;
    }
  }

  .steppers-index {
    @extend .heading-5;
  }
}
